<template>
    <div className="flex max-w-[85rem] px-4 py-5 sm:px-6 lg:px-8 mx-auto items-center">
        <h2 className="sm:max-w-md lg:max-w-2xl text-black w-full font-bold text-2xl md:text-3xl font-serif">
            Tailored Solutions Crafted for Your Business
        </h2>
        <div className="ml-auto hidden sm:flex space-x-12">
            <button aria-label="Left" role="button" type="button" @click="scrollLeft" className="disabled:text-grey-850">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    className="w-[1.875rem] h-[1.875rem] lg:w-8 lg:h-8">
                    <g id="Icon/Arrow/Left">
                        <path id="Icon (Stroke)" fillRule="evenodd" clipRule="evenodd"
                            d="M4.23101 12.54C3.92299 12.2418 3.92299 11.7582 4.23101 11.46L9.63946 6.22366C9.94748 5.92545 10.4469 5.92545 10.7549 6.22366C11.0629 6.52188 11.0629 7.00539 10.7549 7.30361L6.6929 11.2364L19.2113 11.2364C19.6469 11.2364 20 11.5783 20 12C20 12.4217 19.6469 12.7636 19.2113 12.7636L6.6929 12.7636L10.7549 16.6964C11.0629 16.9946 11.0629 17.4781 10.7549 17.7763C10.4469 18.0746 9.94748 18.0746 9.63946 17.7763L4.23101 12.54Z"
                            fill="currentColor"></path>
                    </g>
                </svg>
            </button>
            <button aria-label="Right" role="button" type="button" @click="scrollRight" className="disabled:text-grey-850">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"
                    className="w-[1.875rem] lg:w-8 h-[1.875rem] lg:h-8">
                    <g id="Icon/Arrow/Right">
                        <path id="Icon (Stroke)" fillRule="evenodd" clipRule="evenodd"
                            d="M19.769 12.54C20.077 12.2418 20.077 11.7582 19.769 11.46L14.3605 6.22366C14.0525 5.92545 13.5531 5.92545 13.2451 6.22366C12.9371 6.52188 12.9371 7.00539 13.2451 7.30361L17.3071 11.2364L4.78873 11.2364C4.35313 11.2364 4 11.5783 4 12C4 12.4217 4.35313 12.7636 4.78873 12.7636L17.3071 12.7636L13.2451 16.6964C12.9371 16.9946 12.9371 17.4781 13.2451 17.7763C13.5531 18.0746 14.0525 18.0746 14.3605 17.7763L19.769 12.54Z"
                            fill="currentColor"></path>
                    </g>
                </svg>
            </button>
        </div>
    </div>
    <div className="solutions_container w-full px-4 sm:px-4 lg:px-4 mx-auto mb-10">
        <div className="flex flex-row gap-3 mt-1 md:mt-5 overflow-x-scroll scrollbar-hide transition duration-150 scroll-smooth"
            ref="scrollContainerRef">

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover"
                        src="../assets/beauty_wellness.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Beauty & Wellness
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Arrange and manage appointment
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/event.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Event
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Tracking audience and counting dashboard
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/localbusiness.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Local Business
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Mobile app or loyalty system, Customise Mobile POS
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/wholesale.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Wholesale
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Real-time monitoring of inventory levels
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/manufacturing.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Manufacturing
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Integration of software to the existing machinery
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/retails.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Retail
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Store POS, self-serve and self-checkout
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/cooperate.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Corporate
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            RMS, CMS, Cooperate Mobile App
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/kindergarden.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            Education Industry
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Student, parent and fee management platform
                        </p>
                    </div>
                </div>
            </div>

            <div className="min-w-[17rem] relative block rounded-xl shadow-lg">
                <div
                    className="flex-shrink-0 relative w-full rounded-xl overflow-hidden w-full h-[350px] before:absolute before:inset-x-0 before:w-full before:h-full before:bg-gradient-to-t before:from-gray-900/[.7] before:z-[1]">
                    <img className="w-full h-full absolute top-0 start-0 object-cover" src="../assets/ecommerce.jpg" />
                </div>
                <div className="absolute bottom-0 inset-x-0 z-10">
                    <div className="flex flex-col h-full p-4 sm:p-6">
                        <h3 className="text-lg font-semibold text-white group-hover:text-white/[.8]">
                            E-commerce
                        </h3>
                        <p className="mt-2 text-white/[.8]">
                            Webapp and payment gateway intergration
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    methods: {
    scrollLeft() {
      const container = this.$refs.scrollContainerRef;
      if (container) {
        container.scrollLeft -= 300; // Adjust the scroll amount as needed
      }
    },
    scrollRight() {
      const container = this.$refs.scrollContainerRef;
      if (container) {
        container.scrollLeft += 300; // Adjust the scroll amount as needed
      }
    }
  }
}
</script>