<template>
  <HeaderTemplate />
  <router-view/>
  <FooterTemplate />
</template>

<script>
import HeaderTemplate from "../src/components/HeaderTemplate.vue";
import FooterTemplate from "../src/components/FooterTemplate.vue";
export default {
  name: 'AppView',
  components: {
    HeaderTemplate,
    FooterTemplate
  }
}
</script>
