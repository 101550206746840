<template>
  <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-5">
    <div class="grid lg:grid-cols-6 lg:gap-x-8 xl:gap-x-12 lg:items-center">
      <div class="lg:col-span-3">
        <div class="lg:col-span-3 mb-5 block sm:hidden">
          <img class="w-full rounded-xl" src="../assets/home.jpg" alt="Image Description">
        </div>
        <h1 class="block text-3xl font-bold text-gray-800 sm:text-3xl md:text-4xl lg:text-5xl dark:text-white font-serif">
          Coding Tommorow : Where <span
            class="animate-pulse bg-gradient-to-r from-pink-500 via-green-500 to-violet-500 bg-clip-text text-transparent">Innovation
            Meets
            Precision</span></h1>
        <p class="mt-3 text-lg text-gray-600 dark:text-gray-400">Elevate your business into the digital
          realm,
          where
          the convergence of innovation and precision unlocks new opportunities, streamlines operations,
          and
          propels sustainable growth. Embrace the future with a seamless transition to a dynamic,
          digitally-driven
          ecosystem.</p>
        <a type="button" href="/company-overview"
          class="mt-5 py-3 px-4 shadow-2xl inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gradient-to-r from-indigo-500 to-blue-500 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          Know More About Our Company
        </a>
      </div>
      <div class="lg:col-span-3 mt-10 lg:mt-0 hidden sm:block">
        <img class="w-full rounded-xl" src="../assets/home.jpg" alt="Image Description">
      </div>
    </div>
  </div>
  <div class="max-w-[85rem] px-4 py-5 sm:px-6 lg:px-8 mx-auto">
    <div class="grid grid-cols-3 md:grid-cols-6 items-center gap-12">
      <div>
        <img class="w-16" src="../assets/smartphone.svg" alt="Image Description">
        <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
          <div class="bg-gray-400 w-9 h-0.5"></div>
        </div>
        <div class="mt-5">
          <h3 class="text-sm sm:text-lg font-semibold text-gray-800 dark:text-white">Mobile Application</h3>
        </div>
      </div>
      <div>
        <img class="w-16" src="../assets/web-design.svg" alt="Image Description">
        <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
          <div class="bg-gray-400 w-9 h-0.5"></div>
        </div>
        <div class="mt-5">
          <h3 class="text-sm sm:text-lg font-semibold text-gray-800 dark:text-white">Customize Website</h3>
        </div>
      </div>
      <div>
        <img class="w-16" src="../assets/system.svg" alt="Image Description">
        <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
          <div class="bg-gray-400 w-9 h-0.5"></div>
        </div>
        <div class="mt-5">
          <h3 class="text-sm sm:text-lg font-semibold text-gray-800 dark:text-white">Business System</h3>
        </div>
      </div>
      <div>
        <img class="w-16" src="../assets/iot.svg" alt="Image Description">
        <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
          <div class="bg-gray-400 w-9 h-0.5"></div>
        </div>
        <div class="mt-5">
          <h3 class="text-sm sm:text-lg font-semibold text-gray-800 dark:text-white">Internet of things</h3>
        </div>
      </div>
      <div>
        <img class="w-16" src="../assets/mpos.svg" alt="Image Description">
        <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
          <div class="bg-gray-400 w-9 h-0.5"></div>
        </div>
        <div class="mt-5">
          <h3 class="text-sm sm:text-lg font-semibold text-gray-800 dark:text-white">Mobile POS</h3>
        </div>
      </div>
      <div>
        <img class="w-16" src="../assets/api.svg" alt="Image Description">
        <div class="bg-gradient-to-r from-gray-200 via-gray-50 to-white/0 h-0.5 mt-6">
          <div class="bg-gray-400 w-9 h-0.5"></div>
        </div>
        <div class="mt-5">
          <h3 class="text-sm sm:text-lg font-semibold text-gray-800 dark:text-white">System integration</h3>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-5">
    <IndustrySolution />
  </div>
  <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 mx-auto">
    <div class="lg:grid lg:grid-cols-12 lg:gap-16 lg:items-baseline">
      <div class="lg:col-span-6 lg:col-start-1">

        <div class="mb-8">
          <h2 className="sm:max-w-md lg:max-w-2xl text-black w-full font-bold text-2xl md:text-3xl mb-5 font-serif">
            It's all about your business. money. time.
          </h2>
          <p class="text-gray-600 text-lg">
            Our solutions aren't just powerful; they're designed for maximum adaptability. Integrate our
            products effortlessly into your existing infrastructure and scale up as your business grows.
            Efficiency, meet flexibility.
          </p>
          <a type="button" href="/expertise"
            class="mt-5 shadow-2xl py-3 px-4 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-gradient-to-r from-indigo-500 to-blue-500 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
            Explore Our Services
        </a>
        </div>
      </div>
      <div class="mt-10 lg:mt-0 lg:col-span-6 lg:col-end-13">
        <div class="space-y-6 sm:space-y-8">
          <ul
            class="grid grid-cols-2 divide-y divide-y-2 divide-x divide-x-2 divide-gray-200 overflow-hidden dark:divide-gray-700">
            <li class="flex flex-col -m-0.5 p-4 sm:p-8">
              <div
                class="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold bg-gradient-to-r from-indigo-500 to-blue-500 bg-clip-text text-transparent">
                10y +
              </div>
              <p class="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                of industry experiences. From zero to hero.
              </p>
            </li>

            <li class="flex flex-col -m-0.5 p-4 sm:p-8">
              <div
                class="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold bg-gradient-to-r from-indigo-500 to-blue-500 bg-clip-text text-transparent">
                10b +
              </div>
              <p class="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                lines of code commited to build up a bespoke system
              </p>
            </li>

            <li class="flex flex-col -m-0.5 p-4 sm:p-8">
              <div
                class="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold bg-gradient-to-r from-indigo-500 to-blue-500 bg-clip-text text-transparent">
                95%
              </div>
              <p class="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                defect free on the launched production
              </p>
            </li>

            <li class="flex flex-col -m-0.5 p-4 sm:p-8">
              <div
                class="flex items-end gap-x-2 text-3xl sm:text-5xl font-bold bg-gradient-to-r from-indigo-500 to-blue-500 bg-clip-text text-transparent">
                100%
              </div>
              <p class="text-sm sm:text-base text-gray-600 dark:text-gray-400">
                customize digital solutions for your business
              </p>
            </li>
          </ul>

        </div>
      </div>
    </div>
  </div>
  <BrandScrolling />
  <div className="max-w-[85rem] px-4 py-5 sm:px-6 lg:px-8 mx-auto">
    <h2 className="text-black w-full font-bold text-2xl md:text-3xl mb-5 font-serif">It has never been so easy to digitalize
      your
      business operation.</h2>
    <div className="grid sm:grid-cols-2 lg:grid-cols-3 gap-6 mt-5 md:mt-5">
      <a href='/expertise'
        className="cursor-pointer group rounded-xl overflow-hidden bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 shadow-lg rounded-lg bg-blue-0 p-5">
        <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
          <img
            className="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
            src="../assets/seamless.jpg" alt="Image Description" />
          <span
            className="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-blue-500 text-white py-1.5 px-3 dark:bg-gray-900">
            Business
          </span>
        </div>
        <div className="mt-7">
          <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-200">
            Seamless Business Transformation
          </h3>
          <p className="mt-3 text-gray-800 dark:text-gray-200">
            Say goodbye to manual processes and embrace the efficiency of digitalization, empowering your
            operations for a future of innovation and growth.
          </p>
          <a href='/expertise' className="mt-5 inline-flex items-center gap-x-1 text-blue-600 decoration-2 font-medium">
            Explore more
            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round">
              <path d="m9 18 6-6-6-6" />
            </svg>
          </a>
        </div>
      </a>
      <a href='/expertise'
        className="cursor-pointer group rounded-xl overflow-hidden bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 shadow-lg rounded-lg bg-blue-0 p-5">
        <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
          <img
            className="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
            src="../assets/streamline.jpg" alt="Image Description" />
          <span
            className="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-blue-500 text-white py-1.5 px-3 dark:bg-gray-900">
            Operation
          </span>
        </div>
        <div className="mt-7">
          <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-200">
            Streamlining Your Business for Success
          </h3>
          <p className="mt-3 text-gray-800 dark:text-gray-200">
            Experience the simplicity of digital transformation and witness your business soar to new
            heights.
          </p>
          <a href='/expertise' className="mt-5 inline-flex items-center gap-x-1 text-blue-600 decoration-2 font-medium">
            Explore more
            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round">
              <path d="m9 18 6-6-6-6" />
            </svg>
          </a>
        </div>
      </a>
      <a href='/expertise'
        className="cursor-pointer group rounded-xl overflow-hidden bg-white dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 shadow-lg rounded-lg bg-blue-0 p-5">
        <div className="relative pt-[50%] sm:pt-[70%] rounded-xl overflow-hidden">
          <img
            className="w-full h-full absolute top-0 start-0 object-cover group-hover:scale-105 transition-transform duration-500 ease-in-out rounded-xl"
            src="../assets/effortless.jpg" alt="Image Description" />
          <span
            className="absolute top-0 end-0 rounded-se-xl rounded-es-xl text-xs font-medium bg-blue-500 text-white py-1.5 px-3 dark:bg-gray-900">
            Daily Routine
          </span>
        </div>
        <div className="mt-7">
          <h3 className="text-xl font-semibold text-gray-800 group-hover:text-gray-600 dark:text-gray-200">
            Effortless Digitalization at Your Fingertips
          </h3>
          <p className="mt-3 text-gray-800 dark:text-gray-200">
            Sit back, relax, and watch your business thrive as the advanced digitalization system takes
            charge.
          </p>
          <a href='/expertise' className="mt-5 inline-flex items-center gap-x-1 text-blue-600 decoration-2 font-medium">
            Explore more
            <svg className="flex-shrink-0 w-4 h-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
              viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round"
              strokeLinejoin="round">
              <path d="m9 18 6-6-6-6" />
            </svg>
          </a>
        </div>
      </a>
    </div>
  </div>
  <div class="bg-slate-50 mt-5 md:mt-0 relative">
    <div class="max-w-[85rem] px-4 sm:px-6 lg:px-8 lg:py-14 mx-auto">
      <div class="grid sm:grid-cols-2 gap-12">
        <div class="lg:w-3.2/4 z-[10]">
          <div class="lg:col-span-3 mb-0">
            <img class="w-full aspect-video rounded-xl" src="../assets/handapp.jpg" alt="Image Description">
          </div>
        </div>
        <div class="space-y-6 md:mt-5 lg:space-y-10 z-[10] mb-10 sm:mb-0">
          <div class="flex">
            <span
              class="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
              <svg class="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <path d="M2 3h6a4 4 0 0 1 4 4v14a3 3 0 0 0-3-3H2z" />
                <path d="M22 3h-6a4 4 0 0 0-4 4v14a3 3 0 0 1 3-3h7z" />
              </svg>
            </span>
            <div class="ms-5 sm:ms-8">
              <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                Elevate Your Business with Cutting-edge IT Solutions
              </h3>
              <p class="mt-1 text-gray-600 dark:text-gray-400">
                Begin by assessing your current IT infrastructure, identifying pain points, and understanding your business goals. 
              </p>
            </div>
          </div>
          <div class="flex">
            <span
              class="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
              <svg class="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <path d="M14 9a2 2 0 0 1-2 2H6l-4 4V4c0-1.1.9-2 2-2h8a2 2 0 0 1 2 2v5Z" />
                <path d="M18 9h2a2 2 0 0 1 2 2v11l-4-4h-6a2 2 0 0 1-2-2v-1" />
              </svg>
            </span>
            <div class="ms-5 sm:ms-8">
              <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                Unlock Potential and Drive Progress through IT Innovation
              </h3>
              <p class="mt-1 text-gray-600 dark:text-gray-400">
                Foster collaboration between IT and other business units to ensure alignment between technology initiatives and business objectives. 
              </p>
            </div>
          </div>
          <div class="flex">
            <span
              class="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200 bg-white text-gray-800 shadow-sm mx-auto dark:bg-slate-900 dark:border-gray-700 dark:text-gray-200">
              <svg class="flex-shrink-0 w-5 h-5" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round">
                <path d="M7 10v12" />
                <path
                  d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
              </svg>
            </span>
            <div class="ms-5 sm:ms-8">
              <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                Navigate the Future of Business with Strategic IT Partnerships
              </h3>
              <p class="mt-1 text-gray-600 dark:text-gray-400">
                Work closely with your IT partners to develop a strategic roadmap that outlines how technology can support your business objectives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <svg class="absolute bottom-0" fill-opacity="0.2" id="wave" style="transform:rotate(0deg); transition: 0.3s" viewBox="0 0 1440 150" version="1.1" xmlns="http://www.w3.org/2000/svg"><defs><linearGradient id="sw-gradient-0" x1="0" x2="0" y1="1" y2="0"><stop stop-color="rgba(62, 141.825, 243, 1)" offset="0%"></stop><stop stop-color="rgba(11, 159.94, 255, 1)" offset="100%"></stop></linearGradient></defs><path style="transform:translate(0, 0px); opacity:1" fill="url(#sw-gradient-0)" d="M0,45L14.1,42.5C28.2,40,56,35,85,42.5C112.9,50,141,70,169,80C197.6,90,226,90,254,87.5C282.4,85,311,80,339,80C367.1,80,395,85,424,77.5C451.8,70,480,50,508,50C536.5,50,565,70,593,65C621.2,60,649,30,678,25C705.9,20,734,40,762,47.5C790.6,55,819,50,847,40C875.3,30,904,15,932,10C960,5,988,10,1016,22.5C1044.7,35,1073,55,1101,55C1129.4,55,1158,35,1186,40C1214.1,45,1242,75,1271,92.5C1298.8,110,1327,115,1355,117.5C1383.5,120,1412,120,1440,105C1468.2,90,1496,60,1525,57.5C1552.9,55,1581,80,1609,85C1637.6,90,1666,75,1694,60C1722.4,45,1751,30,1779,22.5C1807.1,15,1835,15,1864,17.5C1891.8,20,1920,25,1948,30C1976.5,35,2005,40,2019,42.5L2032.9,45L2032.9,150L2018.8,150C2004.7,150,1976,150,1948,150C1920,150,1892,150,1864,150C1835.3,150,1807,150,1779,150C1750.6,150,1722,150,1694,150C1665.9,150,1638,150,1609,150C1581.2,150,1553,150,1525,150C1496.5,150,1468,150,1440,150C1411.8,150,1384,150,1355,150C1327.1,150,1299,150,1271,150C1242.4,150,1214,150,1186,150C1157.6,150,1129,150,1101,150C1072.9,150,1045,150,1016,150C988.2,150,960,150,932,150C903.5,150,875,150,847,150C818.8,150,791,150,762,150C734.1,150,706,150,678,150C649.4,150,621,150,593,150C564.7,150,536,150,508,150C480,150,452,150,424,150C395.3,150,367,150,339,150C310.6,150,282,150,254,150C225.9,150,198,150,169,150C141.2,150,113,150,85,150C56.5,150,28,150,14,150L0,150Z"></path></svg>  </div>
</template>

<script>
import IndustrySolution from "../components/IndustrySolution.vue";
import BrandScrolling from "../components/BrandScrolling.vue";
export default {
  name: 'HomeView',
  components: {
    IndustrySolution,
    BrandScrolling
  }
}
</script>
