<template>
    <section
        class="relative bg-[url(https://images.pexels.com/photos/933964/pexels-photo-933964.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)] bg-cover bg-center bg-no-repeat">
        <div class="absolute inset-0 bg-black/75 ">
        </div>
        <div class="relative text-center mx-auto max-w-[85rem] px-4 py-20 sm:px-6 lg:flex lg:items-center lg:px-8">
            <div class="text-center mx-auto">
                <h2 class="text-2xl font-bold md:text-3xl text-white font-serif">Ready to revolutionize your business? Let's
                    chat about which solution fits your needs best!

                </h2>
                <p class="text-xl mt-3 text-white px-3">Got an idea in your mind? Speak to us</p>
                <a href="/contact-us" type="button"
                    class="mt-5 py-2 px-3 inline-flex items-center gap-x-2 font-semibold rounded-lg border border-transparent bg-gradient-to-t from-gray-900 to-gray-600 bg-gradient-to-r text-white hover:bg-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                    Contact Us Now
                </a>
            </div>
        </div>
    </section>
    <!-- <div class="w-full mx-auto bg-gray-100 py-10 lg:py-20 bg-gradient-to-r from-cyan-500 to-blue-500 animate-gradient-x">
      <div class="max-w-[85rem] text-center mx-auto">
        <h2 class="text-2xl font-bold md:text-3xl text-white">Reach Us Out</h2>
        <p class="text-xl mt-3 text-white px-3">Got an idea in your mind? Speak to us</p>
        <a href="/contact-us" type="button" class="mt-5 py-2 px-3 inline-flex items-center gap-x-2 font-semibold rounded-lg border border-transparent bg-gradient-to-t from-gray-900 to-gray-600 bg-gradient-to-r text-white hover:bg-gray-800 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
          Contact Us Now
        </a>
      </div>
    </div> -->
    <div class='bg-[#111d22]'>
        <footer class="w-full max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 mx-auto">
            <div class="grid grid-cols-1 sm:grid-cols-4 lg:grid-cols-5 gap-6 mb-3 md:mb-10">
                <div class="hidden col-span-1 lg:block">
                    <a class="flex-none text-xl font-semibold text-white" href="/" aria-label="Brand">Inventost</a>
                    <p class="mt-3 text-xs sm:text-sm text-white dark:text-gray-400">Made by ♡ in Malaysia.</p>
                </div>

                <div class="hidden md:block col-span-1">
                    <h4 class="font-semibold text-white text-md dark:text-gray-100">Site Map</h4>
                    <div class="mt-3 grid gap-3 text-sm">
                        <p><a class="inline-flex gap-x-2 text-white hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="/">Home</a></p>
                        <p><a class="inline-flex gap-x-2 text-white hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="/company-overview">Company Overview</a></p>
                        <p><a class="inline-flex gap-x-2 text-white hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="/expertise">Expertise</a></p>
                        <!-- <p><a class="inline-flex gap-x-2 text-white hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="/services">What We Do</a></p> -->
                        <p><a class="inline-flex gap-x-2 text-white hover:text-gray-400 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="/contact-us">Contact Us</a></p>
                    </div>
                </div>
                <div class="col-span-full md:col-span-2">
                    <h4 class="font-semibold text-white text-md dark:text-gray-100">About Our Company</h4>
                    <div class="mt-3 grid gap-3 text-sm">
                        <p
                            class="inline-flex gap-x-2 text-white leading-7 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600">
                            Founded by three experienced workers from different industries - our software company is driven
                            by a passion for creativity and a commitment to excellence.
                            Our journey doesn't stop there. We're constantly pushing the boundaries of innovation, exploring
                            emerging technologies, and staying ahead of the curve to ensure our clients stay ahead of the
                            competition.</p>
                    </div>
                </div>
                <div class="col-span-full md:col-span-1">
                    <h4 class="font-semibold text-white text-md dark:text-gray-100">Let’s get in touch to discuss how we
                        could help you.</h4>
                    <div class="mt-3 grid gap-3 text-sm">
                        <p><a class="inline-flex gap-x-2 text-blue-300 hover:text-blue-400 dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                                href="mailto:mail@inventost.com">mail@inventost.com</a></p>
                        <!-- <p
                          class="inline-flex gap-x-2 text-white dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 leading-6">
                          Unit 32-01, Level 32, Tower B, The Vertical Corporate Towers, 8, Jalan Kerinchi, Bangsar South,
                          59200 Kuala Lumpur.</p> -->

                        <p
                            class="inline-flex gap-x-2 text-white dark:text-gray-400 dark:hover:text-gray-200 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600 leading-6">
                            Unit 32-01, Level 32, Tower B, The Vertical Corporate Towers, 8, Jalan Kerinchi, Bangsar South,
                            59200 Kuala Lumpur, Federal Territory of Kuala Lumpur</p>
                    </div>
                </div>
            </div>
            
            <div class="pt-5 mt-5 border-t border-gray-700">
                <div class="sm:flex sm:justify-between sm:items-center">
                    <div class="flex items-center">
                        <div class="mt-3">
                            <p class="mt-1 text-sm sm:text-sm text-white dark:text-gray-400">© {{ new Date().getFullYear() }}
                                Inventost Sdn Bhd 202401000944 (1546795-X). All Rights Reserved.</p>
                        </div>
                </div>
            </div>
        </div>
    </footer>
    <VueSocialChat
      icon
      :attendants="attendants"
    >
      <template #header>
        <p>Click on one of our attendants below to chat on WhatsApp.</p>
      </template>
      <template #button>
        <img
          src="https://raw.githubusercontent.com/ktquez/vue-social-chat/master/src/icons/whatsapp.svg"
          alt="icon whatsapp"
          aria-hidden="true"
        >      
      </template>
      <template #footer>
        <small>We will reply you ASAP</small>
      </template>
    </VueSocialChat>
</div></template>

<script>
export default {
  name: 'FooterTemplate',
  setup () {
    const attendants = [
      {
        app: 'whatsapp',
        label: 'Business Account',
        name: 'Inventost Business Enquiry',
        number: '60125073881',
        avatar: {
          src: 'https://avatars0.githubusercontent.com/u/8084606?s=460&u=20b6499a416cf7129a18e5c168cf387e159edb1a&v=4',
          alt: 'Alan Ktquez avatar'
        }
      },
      // ...
    ]

    return { attendants }
  }
}
</script>
