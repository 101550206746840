<template>
     <div class="max-w-[85rem] px-4 mt-5 sm:px-6 lg:px-8 mx-auto sm:mt-8 sm:mb-8">
        <div
            class="relative flex overflow-x-hidden md:[mask-image:_linear-gradient(to_right,transparent_0,_black_128px,_black_calc(100%-200px),transparent_100%)]">
            <div class="animate-marquee whitespace-nowrap flex">
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/angular.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/laravel.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/html.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/nodejs.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/php.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/bootstrap.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/codeigniter.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/ionic.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/java.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/jquery.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/react.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/vuejs.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/mysql.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/linux.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/aws.png"
                        alt="Image Description"></span>
            </div>
            <div class="absolute top-0 animate-marquee2 whitespace-nowrap flex">
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/angular.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/laravel.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/html.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/nodejs.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/php.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/bootstrap.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto"
                        src="../assets/brand/codeigniter.png" alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/ionic.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/java.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/jquery.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/react.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/vuejs.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/mysql.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/linux.png"
                        alt="Image Description"></span>
                <span class="mx-4 my-auto"><img class="max-w-[42px] sm:max-w-[48px] h-auto" src="../assets/brand/aws.png"
                        alt="Image Description"></span>
            </div>
        </div>
    </div>
</template>
