<template>
    <section
        class="relative bg-[url(https://images.pexels.com/photos/3861967/pexels-photo-3861967.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)] bg-cover bg-center bg-no-repeat">
        <div class="absolute inset-0 bg-black/55 sm:bg-black/35 ">
        </div>
        <div class="relative mx-auto max-w-[85rem] px-4 py-32 sm:px-6 lg:flex lg:items-center lg:px-8">
            <div class="text-center sm:text-left max-lg">
                <p class="mt-4 max-w-lg text-white sm:text-xl/relaxed">
                    Who Are We
                </p>
                <h1 class="text-3xl text-white font-extrabold sm:text-5xl font-serif [text-shadow:_0_1px_0_rgb(0_0_0_/_80%)]">
                    System designer, thinker, collaborators
                </h1>
            </div>
        </div>
    </section>

    <div class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">

        <div class="grid md:grid-cols-5 gap-10">
            <div class="md:col-span-2">
                <div class="max-w-xs">
                    <h2 class="text-3xl font-bold md:text-[3.8rem] md:leading-tight dark:text-white font-serif">Designing a
                        <span
                            class="animate-pulse bg-gradient-to-r from-pink-500 via-green-500 to-violet-500 bg-clip-text text-transparent">pathway</span>
                        from the
                        problem to 💡 solution
                    </h2>
                </div>
            </div>


            <div class="md:col-span-3">

                <div class="hs-accordion-group divide-y divide-gray-200 dark:divide-gray-700">
                    <div class="hs-accordion pb-3 active" id="hs-basic-with-title-and-arrow-stretched-heading-one">
                        <button
                            class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-one">
                            Strategic design
                            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m6 9 6 6 6-6" />
                            </svg>
                            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m18 15-6-6-6 6" />
                            </svg>
                        </button>
                        <div id="hs-basic-with-title-and-arrow-stretched-collapse-one"
                            class="hs-accordion-content w-full overflow-hidden transition-[height] duration-300"
                            aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-one">
                            <p class="text-gray-600 dark:text-gray-400">
                                We’re outcome focussed, not output focussed. We take a big picture approach by assessing
                                your current situation, what the desired goal is, and develop ways to give you the best
                                chance of achieving success.
                                <br />
                                <br />
                                We’re realistic and resourceful. We work smart. That’s our role as IT consultants.
                            </p>
                        </div>
                    </div>

                    <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-two">
                        <button
                            class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-two">
                            Design thinking
                            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m6 9 6 6 6-6" />
                            </svg>
                            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m18 15-6-6-6 6" />
                            </svg>
                        </button>
                        <div id="hs-basic-with-title-and-arrow-stretched-collapse-two"
                            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                            aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-two">
                            <p class="text-gray-600 dark:text-gray-400">
                                <span class="font-bold">Our process for insight and ideas.</span>
                                <br />
                                We help you tackle your problem by understanding through research, developing potential
                                ideas for solutions, and making prototypes to test and refine ideas before we implement.
                            </p>
                        </div>
                    </div>

                    <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-three">
                        <button
                            class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-three">
                            Human centred design
                            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m6 9 6 6 6-6" />
                            </svg>
                            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m18 15-6-6-6 6" />
                            </svg>
                        </button>
                        <div id="hs-basic-with-title-and-arrow-stretched-collapse-three"
                            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                            aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-three">
                            <p class="text-gray-600 dark:text-gray-400">
                                <span class="font-bold">The lens we use to focus ideas.</span>
                                <br />
                                We help you view your problem from the perspective of your people—customers, community,
                                cohort, employees—and understand what they want and need to make their lives easier or
                                better. How? By asking them, not assuming. Your people know you best. Listen to them.
                            </p>
                        </div>
                    </div>

                    <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-four">
                        <button
                            class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-four">
                            Co-design
                            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m6 9 6 6 6-6" />
                            </svg>
                            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m18 15-6-6-6 6" />
                            </svg>
                        </button>
                        <div id="hs-basic-with-title-and-arrow-stretched-collapse-four"
                            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                            aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-four">
                            <p class="text-gray-600 dark:text-gray-400">
                                <span class="font-bold">How we develop solutions together.</span>
                                <br />
                                You and your people bring the inside knowledge and experience, and our job as IT consultants
                                is to bring an outside perspective and process to draw out valuable insights. Between us
                                lies the solutions to your challenge.
                            </p>
                        </div>
                    </div>

                    <div class="hs-accordion pt-6 pb-3" id="hs-basic-with-title-and-arrow-stretched-heading-five">
                        <button
                            class="hs-accordion-toggle group pb-3 inline-flex items-center justify-between gap-x-3 w-full md:text-lg font-semibold text-start text-gray-800 rounded-lg transition hover:text-gray-500 dark:text-gray-200 dark:hover:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                            aria-controls="hs-basic-with-title-and-arrow-stretched-collapse-five">
                            Design doing
                            <svg class="hs-accordion-active:hidden block flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m6 9 6 6 6-6" />
                            </svg>
                            <svg class="hs-accordion-active:block hidden flex-shrink-0 w-5 h-5 text-gray-600 group-hover:text-gray-500 dark:text-gray-400"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m18 15-6-6-6 6" />
                            </svg>
                        </button>
                        <div id="hs-basic-with-title-and-arrow-stretched-collapse-five"
                            class="hs-accordion-content hidden w-full overflow-hidden transition-[height] duration-300"
                            aria-labelledby="hs-basic-with-title-and-arrow-stretched-heading-five">
                            <p class="text-gray-600 dark:text-gray-400">
                                <span class="font-bold">How we bring the ideas to life.</span>
                                <br />
                                We implement the strategy by resourcing and producing the tactical things that will give you
                                the best chance of achieving your goals.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <section
        class="relative bg-[url(https://images.pexels.com/photos/3184357/pexels-photo-3184357.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)] bg-cover bg-center bg-no-repeat">
        <div class="absolute inset-0 bg-white/90 ">
        </div>
        <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-5 z-10 relative pb-14">
            <div class="w-full text-center mt-10">
                <h5 class="tracking-widest mb-5 text-red-500">PLUG + PLAY</h5>
                <h2 className="w-full font-bold text-3xl md:text-5xl mb-10 font-serif">Be it a team of one or one hundred, we
                    plug straight into your business to increase your capacity and capability without all the overhead of a
                    typical agency.</h2>
            </div>
            <div class="max-w-full mx-auto mt-16 sm:mt-20">
                <div class="grid md:grid-cols-2 gap-6 lg:gap-12">
                    <div class="space-y-6 lg:space-y-10">
                        <div class="flex">
                            <svg class="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <rect width="18" height="10" x="3" y="11" rx="2" />
                                <circle cx="12" cy="5" r="2" />
                                <path d="M12 7v4" />
                                <line x1="8" x2="8" y1="16" y2="16" />
                                <line x1="16" x2="16" y1="16" y2="16" />
                            </svg>
                            <div class="ms-5 sm:ms-8">
                                <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Objectivity
                                </h3>
                                <p class="mt-1 text-gray-600 dark:text-gray-400">
                                    The perspective and insight that those on the inside sometimes can’t see.
                                </p>
                            </div>
                        </div>
                        <div class="flex">
                            <svg class="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7.5 4.27 9 5.15" />
                                <path
                                    d="M21 8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16Z" />
                                <path d="m3.3 7 8.7 5 8.7-5" />
                                <path d="M12 22V12" />
                            </svg>
                            <div class="ms-5 sm:ms-8">
                                <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Expertise
                                </h3>
                                <p class="mt-1 text-gray-600 dark:text-gray-400">
                                    The expert skills and knowledge that you don’t have in-house.
                                </p>
                            </div>
                        </div>
                        <div class="flex">
                            <svg class="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <polygon points="13 2 3 14 12 14 11 22 21 10 12 10 13 2" />
                            </svg>
                            <div class="ms-5 sm:ms-8">
                                <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Nimbleness
                                </h3>
                                <p class="mt-1 text-gray-600 dark:text-gray-400">
                                    The ability to be flexible, move fast and efficiently.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="space-y-6 lg:space-y-10">
                        <div class="flex">
                            <svg class="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M6 9H4.5a2.5 2.5 0 0 1 0-5H6" />
                                <path d="M18 9h1.5a2.5 2.5 0 0 0 0-5H18" />
                                <path d="M4 22h16" />
                                <path d="M10 14.66V17c0 .55-.47.98-.97 1.21C7.85 18.75 7 20.24 7 22" />
                                <path d="M14 14.66V17c0 .55.47.98.97 1.21C16.15 18.75 17 20.24 17 22" />
                                <path d="M18 2H6v7a6 6 0 0 0 12 0V2Z" />
                            </svg>
                            <div class="ms-5 sm:ms-8">
                                <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Independance
                                </h3>
                                <p class="mt-1 text-gray-600 dark:text-gray-400">
                                    You don’t need to manage us, we don’t get caught in politics or red-tape.
                                </p>
                            </div>
                        </div>
                        <div class="flex">
                            <svg class="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                                <circle cx="9" cy="7" r="4" />
                                <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                                <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                            </svg>
                            <div class="ms-5 sm:ms-8">
                                <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Capacity
                                </h3>
                                <p class="mt-1 text-gray-600 dark:text-gray-400">
                                    The extra set of hands when you can’t handle the workload internally.
                                </p>
                            </div>
                        </div>
                        <div class="flex">
                            <svg class="flex-shrink-0 mt-2 h-8 w-8 text-gray-800 dark:text-white"
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                                stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                                <path d="M7 10v12" />
                                <path
                                    d="M15 5.88 14 10h5.83a2 2 0 0 1 1.92 2.56l-2.33 8A2 2 0 0 1 17.5 22H4a2 2 0 0 1-2-2v-8a2 2 0 0 1 2-2h2.76a2 2 0 0 0 1.79-1.11L12 2h0a3.13 3.13 0 0 1 3 3.88Z" />
                            </svg>
                            <div class="ms-5 sm:ms-8">
                                <h3 class="text-base sm:text-lg font-semibold text-gray-800 dark:text-gray-200">
                                    Talent
                                </h3>
                                <p class="mt-1 text-gray-600 dark:text-gray-400">
                                    Direct access to a skilled and experienced core team and network of collaborators.
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <div class="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 pt-5 z-10 relative mb-10">
        <div class="w-full text-center sm:mt-10">
            <h5 class="tracking-widest mb-5 text-red-500">The A-team</h5>
            <h2 className="w-full font-bold text-3xl md:text-5xl mb-10 font-serif">Clear thinking,
                straight-talking, fast-moving. Most of our developer gear up with different industry experience</h2>
        </div>
    </div>
    <img src="../assets/line.svg"/>
    <IndustrySolution />
</template>

<script>
import IndustrySolution from "../components/IndustrySolution.vue";
export default {
    name: 'AboutView',
    components: {
        IndustrySolution
    }
}
</script>