<template>
  <section
    class="relative bg-[url(https://images.pexels.com/photos/3760814/pexels-photo-3760814.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)] bg-cover bg-center bg-no-repeat">
    <div class="absolute inset-0 bg-black/55 sm:bg-black/35">
    </div>
    <div class="relative mx-auto max-w-[85rem] px-4 py-28 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="text-center sm:text-left max-lg">
        <p class="mt-4 max-w-lg text-white sm:text-xl/relaxed">
          Reach Us Out
        </p>
        <h1
          class="text-3xl text-white font-extrabold sm:text-5xl font-serif [text-shadow:_0_1px_0_rgb(0_0_0_/_80%)]">
          Visit Our Location
        </h1>
      </div>
    </div>
  </section>
  <section class="bg-white">
    <div class="max-w-[85rem] mx-auto sm:py-16 sm:pb-16 pb-10 px-4 sm:px-6 lg:px-8">
      <div class="mt-5">
        <div class="grid grid-cols-1 md:grid-cols-2 gap-8">
          <div class="rounded-lg overflow-hidden">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d63746.72261644033!2d101.61994776137936!3d3.0490580319197202!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31cc4ba07182723f%3A0xd0a00dbe61076968!2sRegus%20-%20Kuala%20Lumpur%2C%20The%20Vertical%20Corporate%20Towers!5e0!3m2!1sen!2smy!4v1707564440962!5m2!1sen!2smy"
              width="100%" height="450" style="border:0;" allowfullscreen="" loading="lazy" zoom="50%"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
          <div>
            <div class="max-w-full mx-auto rounded-lg overflow-hidden">
              <div class="px-6 py-4">
                <h3 class="text-lg font-medium text-gray-900">Our Address</h3>
                <a href="https://maps.app.goo.gl/us1JMETkigzh5apTA" class="mt-1 text-blue-600">Unit 32-01, Level 32, Tower B, The Vertical Corporate Towers, 8, Jalan Kerinchi, Bangsar South, 59200 Kuala Lumpur, Federal Territory of Kuala Lumpur.</a>
              </div>
              <div class="border-t border-gray-200 px-6 py-4">
                <h3 class="text-lg font-medium text-gray-900">Hours</h3>
                <p class="mt-1 text-gray-600">Monday - Friday: 9.30am - 6.00pm (Lunch hour: 12 - 1pm)</p>
                <p class="mt-1 text-gray-600">Saturday & Sunday: Closed</p>
                <p class="mt-1 text-gray-600">Public Holiday: Closed</p>
              </div>
              <div class="border-t border-gray-200 px-6 py-4">
                <h3 class="text-lg font-medium text-gray-900">Reach Us</h3>
                <p class="mt-1 text-gray-600">Email: <a class="text-blue-600"
                    href="mailto:mail@inventost.com">mail@inventost.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <BrandScrolling></BrandScrolling>
  <IndustrySolution></IndustrySolution>
</template>

<script>
import IndustrySolution from "../components/IndustrySolution.vue";
import BrandScrolling from "../components/BrandScrolling.vue";
export default {
  name: 'ContactView',
  components: {
    IndustrySolution,
    BrandScrolling
  }
}
</script>
