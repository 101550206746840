<template>
  <header class="sticky top-0 inset-x-0 flex flex-wrap md:justify-start md:flex-nowrap z-50 w-full bg-black  text-sm py-3 md:py-0 dark:bg-gray-800 dark:border-gray-700">
  <nav class="relative max-w-[85rem] w-full mx-auto px-4 md:flex md:items-center md:justify-between md:px-6 lg:px-8" aria-label="Global">
    <div class="flex items-center justify-between">
      <a class="flex-none text-xl font-semibold text-white font-serif" href="/" aria-label="Brand">INVENTOST. <span class="text-sm">a software company</span></a>
      <div class="md:hidden">
        <button type="button" class="hs-collapse-toggle w-9 h-9 flex justify-center items-center text-sm font-semibold rounded-lg border border-gray-200 text-gray-800 hover:bg-black disabled:opacity-50 disabled:pointer-events-none dark:text-white dark:border-gray-700 dark:hover:bg-gray-700 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600" data-hs-collapse="#navbar-collapse-with-animation" aria-controls="navbar-collapse-with-animation" aria-label="Toggle navigation">
          <svg class="hs-collapse-open:hidden w-4 h-4" width="16" height="16" fill="#fff" viewBox="0 0 16 16">
            <path fill-rule="evenodd" d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z"/>
          </svg>
          <svg class="hs-collapse-open:block flex-shrink-0 hidden w-4 h-4" width="16" height="16" fill="#fff" viewBox="0 0 16 16">
            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z"/>
          </svg>
        </button>
      </div>
    </div>
    <div id="navbar-collapse-with-animation" class="hs-collapse hidden overflow-hidden transition-all duration-300 basis-full grow md:block">
      <div class="flex flex-col gap-y-4 gap-x-0 mt-5 md:flex-row md:items-center md:justify-end md:gap-y-0 md:gap-x-7 md:mt-0 md:ps-7">
        <a class="font-medium text-white hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="/company-overview">Company Overview</a>
        <a class="font-medium text-white hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="/expertise">Expertise</a>
        <!-- <a class="font-medium text-white hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="#">What We Do</a> -->
        <a class="font-medium text-white hover:text-gray-400 md:py-6 dark:text-gray-400 dark:hover:text-gray-500" href="/contact-us">Contact Us</a>
      </div>
    </div>
  </nav>
</header>
</template>

<script>
export default {
  name: 'HeaderTemplate',
}
</script>
