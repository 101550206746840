<template>
  <section
    class="relative bg-[url(https://images.pexels.com/photos/380769/pexels-photo-380769.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2)] bg-cover bg-center bg-no-repeat">
    <div class="absolute inset-0 bg-black/55 sm:bg-black/35">
    </div>
    <div class="relative mx-auto max-w-[85rem] px-4 py-28 sm:px-6 lg:flex lg:items-center lg:px-8">
      <div class="text-center sm:text-left max-lg">
        <p class="mt-4 max-w-lg text-white sm:text-xl/relaxed">
          Our areas of expertise
        </p>
        <h1
          class="text-3xl text-white font-extrabold sm:text-5xl font-serif [text-shadow:_0_1px_0_rgb(0_0_0_/_80%)]">
          No matter what stage of the project, we're ready to add expertise and value every step of the way.
        </h1>
      </div>
    </div>
  </section>

  <!-- Card Blog -->
  <div class="max-w-[85rem] px-4 py-10 pb-0 sm:px-6 lg:px-8 lg:py-14 mx-auto">
    <!-- Title -->
    <h2 className="w-full font-bold text-3xl md:text-5xl mb-5 font-serif">👋 Hey there! </h2>
    <p class="mt-3 text-lg text-gray-600 dark:text-gray-400 mb-5">At our software company, we've got an exciting lineup of
      solutions that we tailor to your needs. We understand that every business is unique, so we're here to provide the
      perfect fit for you. Check out the cool stuff we offer:</p>
    <!-- End Title -->

    <!-- Grid -->
    <div class="grid sm:grid-cols-2 lg:grid-cols-4 gap-6">
      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/enterprise.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Enterprise Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Boost your business game with tailor-made software solutions like ERP and CRM systems. We're all about making
            your operations smoother.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/cloud.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Cloud Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Take it to the cloud! Our IaaS, PaaS, and SaaS offerings are designed to bring your business to new heights.
            Seamless migration, management, and optimization? Yep, we've got you covered.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/onlineshopping.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            E-Commerce Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Ready to conquer the online market? Dive into our e-commerce solutions, from killer websites to payment
            gateway magic.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/mobileapp.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Mobile App Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Reach your audience on the go! Our mobile apps, whether cross-platform, or hybrid, are crafted for engagement
            and functionality.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/webdevelopment.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Web Development Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Online presence matters. Let us build and maintain your websites and web apps, no matter how complex.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/hospitality.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Hospitality Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            PMS software automates various front and back-office tasks such as reservations, guest check-in/out, room
            assignment, billing, and inventory management.
          </p>
        </div>
      </div>
      <!-- End Card -->      

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/healthcare.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Healthcare IT Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Health meets tech. Our software solutions for healthcare include EHR systems, and powerful healthcare
            analytics.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/iot.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            IoT Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Connect and automate! Dive into our IoT solutions for data-driven insights and streamlined processes.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/softwaredevelopment.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Custom Software Development
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Your business, your rules. We create bespoke software solutions tailored to your unique needs and processes.
          </p>
        </div>
      </div>
      <!-- End Card -->

      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/onlineedu.jpg" alt="Image Description">
        </div>
        <div class="my-2 p-4">
          <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
            Educational Technology Solutions
          </h3>
          <p class="mt-5 text-gray-600 dark:text-gray-400">
            Transform education with our software, from Learning Management Systems to student management tools.
          </p>
        </div>
      </div>
      <!-- End Card -->
      <!-- Card -->
      <div
        class="group flex flex-col h-full border bg-white border-gray-200 border-transparent shadow-lg transition-all duration-300 rounded-xl  dark:border-gray-700 dark:hover:border-transparent dark:hover:shadow-black/[.4] dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
        href="#">
        <div class="aspect-w-16 aspect-h-11">
          <img class="w-full object-cover rounded-t-xl" src="../assets/data.jpg" alt="Image Description">
        </div>
      <div class="my-2 p-4">
        <h3 class="text-xl font-semibold text-gray-800 dark:text-gray-300 dark:group-hover:text-white">
          Data Management and Analytics Solutions
        </h3>
        <p class="mt-5 text-gray-600 dark:text-gray-400">
          Numbers speak volumes. Our solutions cover data storage, analysis, and visualization, turning raw data into
          actionable insights.
        </p>
      </div>
    </div>
    <!-- End Card -->
  </div>
  <!-- End Grid -->

  <!-- Card -->

  <!-- End Card -->
</div>
<!-- End Card Blog -->
<IndustrySolution /></template>

<script>
import IndustrySolution from "../components/IndustrySolution.vue";
export default {
  name: 'SolutionsView',
  components: {
    IndustrySolution
  }
}
</script>