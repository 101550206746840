import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import './index.css'
import "preline/preline";
import VueSocialChat from 'vue-social-chat'
import 'vue-social-chat/dist/style.css'

createApp(App).use(router).use(VueSocialChat).mount('#app')
