import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import AboutView from '../views/AboutView.vue'
import SolutionsView from '../views/SolutionsView.vue'
import ContactView from '../views/ContactView.vue'

const routes = [
  {
    path: '/',
    component: HomeView
  },
  {
    path: '/company-overview',
    component: AboutView
  },
  {
    path: '/expertise',
    component: SolutionsView
  },
  {
    path: '/contact-us',
    component: ContactView
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.afterEach((to, from, failure) => {
  if (!failure) {
    setTimeout(() => {
      console.log('reload')
      window.HSStaticMethods.autoInit();
    }, 100)
  }
});

export default router
